import { createApiFactory, createComponentExtension, createPlugin, createRoutableExtension, discoveryApiRef, fetchApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { plexoApiRef } from './api/PlexoApi';
import { PlexoClient } from './api/PlexoClient';
import { PLEXO_APPLICATION_CODE } from './constants';
import { Entity } from '@backstage/catalog-model';
import { rootRouteRef } from './routes';

export const plexoPlugin = createPlugin({
  id: 'plexo',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: plexoApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef
      },
      factory: ({ discoveryApi, fetchApi, identityApi }) =>
        new PlexoClient({
          discoveryApi,
          fetchApi,
          identityApi,
        }),
    }),
  ],
});

/**
  * @public
  * @param entity - The entity to check for the plexo id annotation
*/
export const isPlexoAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[PLEXO_APPLICATION_CODE]) ;

export const PlexoPage = plexoPlugin.provide(
  createRoutableExtension({
    name: 'PlexoPage',
    component: () =>
      import('./components/PlexoComponent').then(m => m.PlexoComponent),
    mountPoint: rootRouteRef,
  }),
);
export const EntityPlexoCard = plexoPlugin.provide(
  createComponentExtension({
      name: 'EntityPlexocard',
      component: {
        lazy: () =>
        import('./components/PlexoComponent').then(m => m.PlexoComponent),     
      },
      }),
)
