import { createApiRef } from '@backstage/core-plugin-api';

export interface PlexoResponse  {
  data: {
    getApplicationData: PlexoApplicationData;
  }
};

export interface PlexoApplicationData  {
  PK: string;
  projectName: string;
  description: string;
  account: string;
  infrastructure: {
    architecture: string;
    version: string;
    latestVersionAvailable: string;
  };
  deployment?: Deployment;
  deployments: Array <Deployment> ;
};

export type Deployment ={
  status: string;
  environments: string [];
  date: number;
  revisionId: string;
  
};

export const plexoApiRef = createApiRef<PlexoApi>({
  id: 'plugin.plexo.service',
});

export type PlexoApi = {
    getPlexoApplicationData(
      awCode: string,
  ): Promise<PlexoResponse | undefined>;
  
};
