import { createUnifiedTheme, createBaseThemeOptions, palettes, genPageTheme, shapes } from '@backstage/theme';

import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';

// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}

export const bancolombiaLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#383736',
        light: '#5f5f5e',
        dark: '#272625'
      },
      secondary: {
        main: '#fdda24',
        light: '#fde14f',
        dark: '#b19819'
      },
      error: {
        main: '#FF5630',
        light: '#FF8F73',
        dark: '#DE350B'
      },
      warning: {
        main: '#FFAB00',
        light: '#FFE380',
        dark: '#FF8B00'
      },
      success: {
        main: '#36B37E',
        light: '#79F2C0',
        dark: '#006644'
      },
      info: {
        main: '#59CBE8',
        light: '#90DCF0',
        dark: '#51b9d3'
      },
      navigation: {
        ...palettes.light.navigation,
        background: '#383736',
        color: '#FFFFFF',
        indicator: '#fdda24',
        navItem: {
          hoverBackground: '#fdda24'
        }
      },
      text: {
        primary: '#2c2a29',
      },
      background: {
        default: '#FFFFFF'
      },
    },
    fontFamily: 'Roboto, sans-serif',
    defaultPageTheme: 'home'
  }),
  components: {
    CatalogReactUserListPicker: {
      styleOverrides: {
        title: {
          textTransform: 'none'
        },
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&$completed': {
            color: theme.palette.success.main
          }
        })
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0
        },
        standardError: ({ theme }) => ({
          color: theme.palette.background.default,
          backgroundColor: theme.palette.error.dark,
          '& $icon': {
            color: theme.palette.background.default,
          },
        }),
        standardInfo: ({ theme }) => ({
          color: theme.palette.background.default,
          backgroundColor: theme.palette.primary.dark,
          '& $icon': {
            color: theme.palette.background.default,
          }
        }),
        standardSuccess: ({ theme }) => ({
          color: theme.palette.background.default,
          backgroundColor: theme.palette.success.dark,
          '& $icon': {
            color: theme.palette.background.default,
          }
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.secondary.light,
          '& $icon': {
            color: theme.palette.grey[700]
          },
        })
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&[aria-expanded=true]': {
            color: theme.palette.background.default,
          },
          '&[aria-expanded=true] path': {
            fill: theme.palette.background.default
          },
          '&&.Mui-focused fieldset': {
            borderColor: theme.palette.secondary.main,
          }
        })
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: ({ theme }) => ({
          '&:after': {
            borderBottom: `2px solid ${theme.palette.secondary.main}`
          }
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&&.Mui-focused fieldset': {
            borderColor: theme.palette.secondary.main
          }
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          textTransform: 'none',
          '&:hover':{
            backgroundColor: '#d9dadd'
          }
        },
        colorSecondary: ({ theme }) => ({
          color: theme.palette.background.default
        }),
        containedPrimary: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.background.default
          },
          color: theme.palette.background.default
        }),
        outlinedPrimary:{
          '&:hover':{
            backgroundColor: '#d9dadd'
          }
        },
        textPrimary: {
          '&:hover':{
            backgroundColor: '#d9dadd'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          margin: 4
        },
        sizeSmall: ({ theme }) => ({
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.info.light,
          '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.info.main
          }
        }),
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&[aria-expanded]': {
            color: theme.palette.primary.main,
            border: `2px solid ${theme.palette.secondary.main}`
          }
        }),
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.light
        })
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: ({ theme }) => ({
          '&$checked': {
            color: theme.palette.primary.light
          }
        })
      }
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.primary.light
          }
        })
      }
    },
    MuiCard:{
      styleOverrides:{
        root: ({theme})=>({
          borderRadius: theme.spacing(2),
          boxShadow: `0 8px 12px 0 rgba(0 0 0 / 20%),0 1px 8px 0 rgba(43,42,41,.0784313725)`
        })
      }
    }
  },
  fontFamily: 'Roboto, sans-serif',
  defaultPageTheme: 'home',
    pageTheme: {
      home: genPageTheme({ colors: ['#33b790', '#00a574'], shape: shapes.wave }),
      tool: genPageTheme({ colors: ['#4f3671', '#9063cd'], shape: shapes.wave2 }),
      service: genPageTheme({colors: ['#51b9d3', '#59cbe8'], shape: shapes.wave2}),
      website: genPageTheme({colors: ['#1e4dc4', '#4b71d0'], shape: shapes.wave2}),
      library: genPageTheme({colors: ['#ff3030', '#ff7373'],shape: shapes.wave2,}),
      app: genPageTheme({ colors: ['#e8743b', '#ff9967'], shape: shapes.wave2 }),
      apis: genPageTheme({ colors: ['#00a574', '#33b790'], shape: shapes.round }),
      documentation: genPageTheme({colors: ['#e4717d', '#efaab1'],shape: shapes.wave2}),
      other: genPageTheme({ colors: ['#2c2929', '#383736'], shape: shapes.wave2 }),
    },
});