import { createUnifiedTheme, createBaseThemeOptions, palettes, genPageTheme, shapes } from '@backstage/theme';

import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';

// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}
export const bancolombiaDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#fdda24',
        light: '#fde14f',
        dark: '#b19819'
      },
      secondary: {
        main: '#383736',
        light: '#5f5f5e',
        dark: '#272625'
      },
      error: {
        main: '#e15759',
        light: '#eb9899',
        dark: '#a21717'
      },
      warning: {
        main: '#ff7f41',
        light: '#ff9967',
        dark: '#e8743b'
      },
      success: {
        main: '#04ad7b',
        light: '#20dfa7',
        dark: '#086348'
      },
      info: {
        main: '#a682d7',
        light: '#c5ade5',
        dark: '#835abb'
      },
      navigation: {
        ...palettes.dark.navigation,
        background: '#383736',
        color: '#FFFFFF',
        indicator: '#fdda24',
        navItem: {
          hoverBackground: '#fdda24'
        }
      },
      text: {
        primary: '#FFFFFF',
      },
      background: {
        default: '#272625'
      },
    }
  }),
  components: {
    CatalogReactUserListPicker: {
      styleOverrides: {
        title: {
          textTransform: 'none'
        },
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&$completed': {
            color: theme.palette.success.main
          }
        }),
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: ({ theme }) => ({
          '&:after': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
          }
        }),
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main
          }
        }),
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          textTransform: 'none',
        },
        colorSecondary: ({ theme }) => ({
          color: theme.palette.primary.light
        }),
        containedPrimary: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.secondary.dark
          },
          color: theme.palette.secondary.dark
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          margin: 4
        },
        sizeSmall: ({ theme }) => ({
          color: theme.palette.secondary.dark,
          backgroundColor: theme.palette.info.light,
          '&:hover': {
            color: theme.palette.secondary.dark,
            backgroundColor: theme.palette.info.main
          }
        }),
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&[aria-expanded]': {
            border: `2px solid ${theme.palette.primary.main}`
          }
        }),
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: ({ theme }) => ({
          '&$checked': {
            color: theme.palette.primary.main,
          },
        }),
      }
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none'
        },
      }
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          '&:hover': {
            color: theme.palette.grey[500]
          }
        }),
      }
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(2),
          boxShadow: `0 8px 12px 0 #0000005c, 0 1px 8px 0 rgba(43, 42, 41, .0784313725)`
        })
      }
    }
  },

  fontFamily: 'Roboto, sans-serif',
  defaultPageTheme: 'home',
    pageTheme: {
      home: genPageTheme({ colors: ['#084a37', '#086348'], shape: shapes.wave }),
      tool: genPageTheme({ colors: ['#4f3671', '#9063cd'], shape: shapes.wave2 }),
      service: genPageTheme({colors: ['#3f90a5', '#51b9d3'], shape: shapes.wave2}),
      website: genPageTheme({colors: ['#1e4dc4', '#4b71d0'], shape: shapes.wave2}),
      library: genPageTheme({colors: ['#ff3030', '#ff7373'],shape: shapes.wave2,}),
      app: genPageTheme({ colors: ['#e8743b', '#ff7f41'], shape: shapes.wave2 }),
      apis: genPageTheme({ colors: ['#086348', '#084a37'], shape: shapes.round }),
      documentation: genPageTheme({colors: ['#b65a64', '#e4717d'],shape: shapes.wave2}),
      other: genPageTheme({ colors: ['#2c2929', '#383736'], shape: shapes.wave2 }),
    },

});
