export const PLEXO_APPLICATION_CODE = 'plexo.apps.bancolombia.com/awcode';
import { intervalToDuration, formatDuration } from "date-fns"

export const formatOptions = {
    delimiter: ", ",
    format: ["years", "months", "weeks", "days", "minutes"]
};

const formatDHM = {
    delimiter: ", ",
    format: ["days", "hours", "minutes"]
};

export function getDuration(utcSeconds: number): string {
    const dateBefore = new Date(utcSeconds*1000);
    const duration = intervalToDuration({ start: dateBefore, end: new Date() });
    const result = formatDuration(duration, formatDHM);
    return result;

}