import { DiscoveryApi, FetchApi, IdentityApi } from '@backstage/core-plugin-api';
import { gql } from 'graphql-request';
import { PlexoResponse } from './PlexoApi';

const query = gql`
  query getApplicationData($PK: String!) {
    getApplicationData(PK: $PK) {
        PK,
        projectName,
        description,
        account,
        infrastructure {
            architecture
            version
            latestVersionAvailable
        }
        lastDeployment {
            status
            environments
            date
            revisionId
        }
        deployments {
            status
            environments
            revisionId
            date
        }
    }
  }
`;

export class PlexoClient {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
  identityApi: IdentityApi;

  constructor({
    discoveryApi,
    fetchApi,
    identityApi,
  }: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
    this.identityApi = identityApi;
  }

  private async callApi<T>(
    awCode: string,
  ): Promise<T | undefined> {
    const apiUrl = `${await this.discoveryApi.getBaseUrl('proxy')}/plexo`;
    const variables = { PK: awCode };

    const response = await this.fetchApi.fetch(
      apiUrl,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query, variables }),
      },
    ) as Response;
    
    if (response.status === 200) {
      return (await response.json()) as T;
    }
    throw new Error(
      `Plexo API call failed: ${response.status}:${response.statusText}`,
    );
  }

  async getPlexoApplicationData(
    awCode: string,
  ): Promise<PlexoResponse | undefined> {
    if (!awCode) {
      throw new Error('Plexo awCode is required');
    }
    return this.callApi(awCode);
  }
}
